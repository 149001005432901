<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import CalendarApiService from "@/services/calendarService";
import InputTag from 'vue-input-tag';

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "emails",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
    InputTag,
  },
  data() {
    return {
      title: "Emails",
      isLoading: false,
      progressConfig: [],
      installersConfig: [],
      shippersConfig: [],
      productionConfig: [],
      progressOpened: false,
      installersOpened: false,
      shippersOpened: false,
      productionOpened: false,
      emailConfig: {
        id: null,
        preferredLanguage: null,
        deliveryEmailConfig: [],
        installationEmailConfig: [],
        productionEmailConfig: []
      },
      items: [
        {
          text: "emails",
          href: "/emails",
        },
        {
          text: "",
          active: true,
        },
      ],
      emailLanguages: [
        "French",
        "English"
      ]
    };
  },
  beforeCreate(){

  },  
  created (){
    //Load installers
    this.isLoading = true;

    let task1 = CalendarApiService.getProgressCalendarConfig().then((response) => {

      let orderedProgressConfig = response.data.data.sort((a,b) => (a.progress.order > b.progress.order) ? 1 : ((b.progress.order > a.progress.order) ? -1 : 0));

      this.progressConfig = orderedProgressConfig;
    });

    let task2 = CalendarApiService.getShippersCalendarConfig().then((response) => {
      this.shippersConfig = response.data.data;
    });  

    let task3 = CalendarApiService.getInstallersCalendarConfig().then((response) => {
      this.installersConfig = response.data.data;
    });

    let task4 = CalendarApiService.getProductionCalendarConfig().then((response) => {
      this.productionConfig = response.data.data;
    });    

    let task5 = CalendarApiService.getEmailConfig().then((response) => {
      var emailConfig = response.data.data;
      this.emailConfig.preferredLanguage = emailConfig.preferredLanguage;

      this.setEmailConfigurations(emailConfig)
    });    

    Promise.all([task1,task2, task3, task4, task5]).then(() => {
      this.isLoading = false;
    });

  },
  mounted() {},
  computed: {         
  },
  methods: {
    getEmptyEmailSendValuesModel(measureType){
      return {
        id: 0,
        measureType: measureType,
        sendWithEmail: false
      }
    },
    setEmailConfigurations(emailConfigData){
      this.emailConfig = emailConfigData;

      //ajouter les metrics manquantes
      for(var measureType of this.measureTypes){
        //installation
        if(this.emailConfig.installationEmailConfig == null){
          let model = this.getEmptyEmailSendValuesModel(measureType);
          this.emailConfig.installationEmailConfig = {
            emailSendValues: []
          };
          this.emailConfig.installationEmailConfig.emailSendValues.push(model);
        }else if(this.emailConfig.installationEmailConfig.emailSendValues.filter(x => {return x.measureType == measureType}).length == 0){
          //ajouter a la config
          let model = this.getEmptyEmailSendValuesModel(measureType);
          this.emailConfig.installationEmailConfig.emailSendValues.push(model);
        }

        //livraison
        if(this.emailConfig.deliveryEmailConfig == null){
          let model = this.getEmptyEmailSendValuesModel(measureType);
          this.emailConfig.deliveryEmailConfig = {
            emailSendValues: []
          };
          this.emailConfig.deliveryEmailConfig.emailSendValues.push(model);
        }else if(this.emailConfig.deliveryEmailConfig.emailSendValues.filter(x => {return x.measureType == measureType}).length == 0){
          //ajouter a la config
          let model = this.getEmptyEmailSendValuesModel(measureType);
          this.emailConfig.deliveryEmailConfig.emailSendValues.push(model);
        }

        //production
        if(this.emailConfig.productionEmailConfig == null){
          let model = this.getEmptyEmailSendValuesModel(measureType);
          this.emailConfig.productionEmailConfig = {
            emailSendValues: []
          };
          this.emailConfig.productionEmailConfig.emailSendValues.push(model);
        }else if(this.emailConfig.productionEmailConfig.emailSendValues.filter(x => {return x.measureType == measureType}).length == 0){
          //ajouter a la config
          let model = this.getEmptyEmailSendValuesModel(measureType);
          this.emailConfig.productionEmailConfig.emailSendValues.push(model);
        }
      }




      // for(var deliveryEmailSendValue of this.emailConfig.deliveryEmailConfig.emailSendValues){
      //   let assocValue = emailConfigData.deliveryEmailConfig?.emailSendValues.filter((x) => { return x.measureType == deliveryEmailSendValue.measureType})
      //   if(assocValue != null && assocValue.length > 0){
      //     assocValue = assocValue[0]

      //     deliveryEmailSendValue.sendWithEmail = assocValue.sendWithEmail;
      //   }
      // }

      // for(var installationEmailSendValue of this.emailConfig.installationEmailConfig.emailSendValues){
      //   let assocValue = emailConfigData.installationEmailConfig?.emailSendValues.filter((x) => { return x.measureType == installationEmailSendValue.measureType})
      //   if(assocValue != null && assocValue.length > 0){
      //     assocValue = assocValue[0]

      //     installationEmailSendValue.sendWithEmail = assocValue.sendWithEmail;
      //   }
      // }

      // for(var productionEmailSendValue of this.emailConfig.productionEmailConfig.emailSendValues){
      //   let assocValue = emailConfigData.productionEmailConfig?.emailSendValues.filter((x) => { return x.measureType == productionEmailSendValue.measureType})
      //   if(assocValue != null && assocValue.length > 0){
      //     assocValue = assocValue[0]

      //     productionEmailSendValue.sendWithEmail = assocValue.sendWithEmail;
      //   }
      // }
    },
    submitConfig(){

      if(this.emailConfig.preferredLanguage == null){
        this.emailConfig.preferredLanguage = "French"
      }

      let config = {
        progressCalendarConfig: this.progressConfig,
        shippersCalendarConfig: this.shippersConfig,
        installersCalendarConfig: this.installersConfig,
        productionCalendarConfig: this.productionConfig,
        emailConfig: this.emailConfig
      }

      this.isLoading = true;
      CalendarApiService.updateCalendarItemsConfig(config).then(() => {
        this.isLoading = false;
        this.$bvToast.toast(this.$t('general.emailsSavedContent'), {
          title: this.$t('general.saved'),
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'success',
          solid: true
        })
      })     
    },
    
  }
};
</script>

<template>
  <Layout>  
    <PageHeader :title="$t('general.emails')" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <loading :active.sync="isLoading" 
              :can-cancel="false"
              color='#f1b44c'
              :width=70
              :height=70
              loader='dots'
              :is-full-page="false">
            </loading>    
            <div class="row mb-4">
              <div class="col-12">
                <p class="card-title-desc mb-3">
                  {{$t('general.preferredLanguageForemails')}}
                </p> 
                <multiselect
                  style="max-width:200px;"
                  v-model="emailConfig.preferredLanguage"
                  :options="emailLanguages"
                  :placeholder="$t('general.selectLanguage')"
                  :show-labels="false">
                    <template slot="singleLabel" slot-scope="props">
                      <span >{{ $t('general.' + props.option) }}</span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <span >{{ $t('general.' + props.option)}}</span>
                    </template>
                </multiselect>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p class="card-title-desc mb-3">
                  {{$t('general.addEmailsTitle')}}
                </p> 
                <b-form @submit.prevent="submitConfig" @keydown.enter.prevent style="max-width:1200px;">
                  <div class="mb-1">
                    <div class="mb-2 p-2" :style="{'background': (progressOpened ? '#f4f6f7 none repeat scroll 0% 0%' : 'white')}" style="display:flex; align-items: center; justify-content:space-between; cursor:pointer;" @click="progressOpened = !progressOpened">
                      <h5 class="mb-0 mr-5">{{$t('general.progress')}}</h5>
                      <i v-bind:class="{'fas fa-angle-down': !progressOpened,  'fas fa-angle-up': progressOpened}" style="font-size:1.5em;"></i>
                    </div>
                    <b-collapse v-model="progressOpened" class="table-responsive" >
                      <table class="table mb-0 table-hover">
                        <thead class="thead-light" style="display:none;">
                          <tr>
                            <th>{{$t('general.name')}}</th>
                            <th>{{$t('general.emails')}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(progressConfig, index) in progressConfig" :key="index">
                            <td>{{progressConfig.progress.name}}</td>
                            <td style="width:65%;">
                              <InputTag 
                                v-model="progressConfig.emailsOnEventCreate"
                                validate="email"
                                :add-tag-on-blur="true"
                                :placeholder="$t('general.enterEmails')">
                              </InputTag>
                            </td>                     
                          </tr>                         
                        </tbody>
                      </table>
                    </b-collapse>
                  </div>

                  <div class="mb-1">
                    <div class="mb-2 p-2" :style="{'background': (shippersOpened ? '#f4f6f7 none repeat scroll 0% 0%' : 'white')}" style="display:flex; align-items: center; justify-content:space-between; cursor:pointer;" @click="shippersOpened = !shippersOpened">
                      <h5 class="mb-0 mr-5">{{$t('general.delivery')}}</h5>
                      <i v-bind:class="{'fas fa-angle-down': !shippersOpened,  'fas fa-angle-up': shippersOpened}" style="font-size:1.5em;"></i>
                    </div>
                    
                    <b-collapse v-model="shippersOpened" class="table-responsive">
                      <div class="p-2">
                        <label>{{$t('general.workOrderValuesToSendWithEmail')}}</label>
                        <div style="display:flex;">
                          <b-form-checkbox
                              v-for="(sendValue, i) in emailConfig.deliveryEmailConfig.emailSendValues"
                              :key="i"
                              v-model="sendValue.sendWithEmail"
                              class="custom-checkbox mb-3 mr-3"
                              checked
                            >{{$t('general.' + sendValue.measureType)}}</b-form-checkbox>
                        </div>                        
                      </div>
                      <table class="table mb-0 table-hover">
                        <thead class="thead-light" style="display:none;">
                          <tr>
                            <th>{{$t('general.name')}}</th>
                            <th>{{$t('general.emails')}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(shipperConfig, index) in shippersConfig" :key="index">
                            <td>{{shipperConfig.shipper.name}}</td>
                            <td style="width:65%;">
                              <InputTag 
                                v-model="shipperConfig.emailsOnEventCreate"
                                validate="email"
                                :add-tag-on-blur="true"
                                :placeholder="$t('general.enterEmails')">>
                              </InputTag> 
                            </td>                     
                          </tr>                         
                        </tbody>
                      </table>
                    </b-collapse>
                  </div> 

                  <div class="mb-1">
                    <div class="mb-2 p-2" :style="{'background': (installersOpened ? '#f4f6f7 none repeat scroll 0% 0%' : 'white')}" style="display:flex; align-items: center; justify-content:space-between; cursor:pointer;" @click="installersOpened = !installersOpened">
                      <h5 class="mb-0 mr-5">{{$t('general.installation')}}</h5>
                      <i v-bind:class="{'fas fa-angle-down': !installersOpened,  'fas fa-angle-up': installersOpened}" style="font-size:1.5em;"></i>
                    </div>
                    
                    <b-collapse v-model="installersOpened" class="table-responsive">
                      <div class="p-2">
                        <label>{{$t('general.workOrderValuesToSendWithEmail')}}</label>
                        <div style="display:flex;">
                          <b-form-checkbox
                              v-for="(sendValue, i) in emailConfig.installationEmailConfig.emailSendValues"
                              :key="i"
                              v-model="sendValue.sendWithEmail"
                              class="custom-checkbox mb-3 mr-3"
                              checked
                            >{{$t('general.' + sendValue.measureType)}}</b-form-checkbox>
                        </div>                        
                      </div>
                      <table class="table mb-0 table-hover">
                        <thead class="thead-light" style="display:none;">
                          <tr>
                            <th>{{$t('general.name')}}</th>
                            <th>{{$t('general.emails')}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(installerConfig, index) in installersConfig" :key="index">
                            <td>{{installerConfig.installer.name}}</td>
                            <td style="width:65%;">
                              <InputTag 
                                v-model="installerConfig.emailsOnEventCreate"
                                validate="email"
                                :add-tag-on-blur="true"
                                :placeholder="$t('general.enterEmails')">
                              </InputTag>  
                            </td>                     
                          </tr>                         
                        </tbody>
                      </table>
                    </b-collapse>
                  </div>  

                  <div class="mb-1">
                    <div class="mb-2 p-2" :style="{'background': (productionOpened ? '#f4f6f7 none repeat scroll 0% 0%' : 'white')}" style="display:flex; align-items: center; justify-content:space-between; cursor:pointer;" @click="productionOpened = !productionOpened">
                      <h5 class="mb-0 mr-5">{{$t('general.production')}}</h5>
                      <i v-bind:class="{'fas fa-angle-down': !productionOpened,  'fas fa-angle-up': productionOpened}" style="font-size:1.5em;"></i>
                    </div>
                    
                    <b-collapse v-model="productionOpened" class="table-responsive">
                      <div class="p-2">
                        <label>{{$t('general.workOrderValuesToSendWithEmail')}}</label>
                        <div style="display:flex;">
                          <b-form-checkbox
                              v-for="(sendValue, i) in emailConfig.productionEmailConfig.emailSendValues"
                              :key="i"
                              v-model="sendValue.sendWithEmail"
                              class="custom-checkbox mb-3 mr-3"
                              checked
                            >{{$t('general.' + sendValue.measureType)}}</b-form-checkbox>
                        </div>                        
                      </div>
                      <InputTag 
                        v-model="productionConfig.emailsOnEventCreate"
                        validate="email"
                        :add-tag-on-blur="true"
                        :placeholder="$t('general.enterEmails')">
                      </InputTag>  
                    </b-collapse>
                  </div>                                                      

                  <div class="row pt-3">
                    <b-button type="submit" class="ml-1" variant="success" >{{$t('general.save')}}</b-button>
                  </div>                                 
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
